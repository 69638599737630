@import './../../../../scss/theme-bootstrap';

.staggered-grid-tout {
  @include breakpoint($small-down) {
    .staggered-grid-tout__inner {
      flex-direction: column;
      .video--icon-only {
        .videojs-video__player {
          opacity: 0;
        }
      }
    }
    .staggered-grid-tout__media {
      width: 100%;
    }
    .staggered-grid-tout__content {
      padding: 0.5em;
      order: 2;
      text-align: center;
      width: 100%;
    }
    .staggered-grid-tout__content-pad {
      padding: 0;
    }
  }
  position: relative;
  &__inner {
    display: flex;
    @include breakpoint($medium-up) {
      flex-direction: column;
    }
  }
  &__content {
    width: 50%;
    @include breakpoint($medium-up) {
      order: 2;
      text-align: center;
      width: 100%;
    }
  }
  &__content-pad {
    padding: 0 0 0 1em;
    @include breakpoint($medium-up) {
      padding: 1em 0 0;
    }
  }
  &__content-header {
    @include body-large;
    p:only-child {
      margin-bottom: 0;
    }
  }
  &__media {
    position: relative;
    width: 50%;
    @include breakpoint($medium-up) {
      order: 1;
      width: 100%;
    }
  }
  // stagger ordering of elements when inside the formatter
  .staggered-grid-formatter__grid-item:nth-child(even) & {
    .staggered-grid-tout__media {
      order: 2;
      @include breakpoint($medium-up) {
        order: 1;
      }
    }
    .staggered-grid-tout__content-pad {
      padding: 0 1em 0 0;
      @include breakpoint($medium-up) {
        padding: 1em 0 0;
      }
    }
  }
  &__content-text {
    @include body-small;
    margin: 0.5rem auto;
  }
  // @todo the button width is aggressive for mobile - should we revisit or is this OK?
  .button {
    min-width: auto;
    width: 100%;
    @include breakpoint($medium-up) {
      min-width: 250px; // resets the default
      width: auto;
    }
  }
  .mantle-media-asset {
    width: 100%;
    height: auto; // mantle 100% height squashes our images
  }
}
